import React, { useEffect, useState, Fragment } from "react";
import Footer from "../Components/Reuseable/Footer.jsx";
import CoptRight from "../Components/Reuseable/Copyrights";
import Cookies from "js-cookie";
import { Accordion } from "react-bootstrap";
import "../../Webiste/Components/CSS/pagesCSS/dashboard.css";
import ScrollContainer from "react-indiana-drag-scroll";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import "../Components/CSS/RaceCardCSS/racecard.css";
import { useSelector, useDispatch } from "react-redux";
import Moment from "react-moment";
import { fetchSireHistoryForStatisitics } from "../redux/getReducer/getSireHistoryForStatisitics";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import { fetchSireSnippets } from "../redux/getReducer/getSireSnippets";
import Select from "react-select";
import { fetchResultYearFilterForStats } from "../redux/getStatsFilterReduce/getResultYearFilterForStats";
import { fetchRaceTypeFilterForStats } from "../redux/getStatsFilterReduce/getRaceTypeFilterForStats";
import { fetchTrackLengthFilterForStats } from "../redux/getStatsFilterReduce/getTrackLengthFilterForStats";
import { fetchHorseKindFilterForStats } from "../redux/getStatsFilterReduce/getHorseKindFilterForStats";
import { fetchRaceKindFilterForStats } from "../redux/getStatsFilterReduce/getRaceKindFilterForStats";
import { fetchRaceNationalityFilterForStats } from "../redux/getStatsFilterReduce/getRaceNationalityFilterForStats";
import { fetchRaceCourseFilterForStats } from "../redux/getStatsFilterReduce/getRaceCourseFilterForStats";
import { fetchDatesFilterForStats } from "../redux/getStatsFilterReduce/getDatesFilterForStats";
import { fetchGroundTypeFilterForStats } from "../redux/getStatsFilterReduce/getGroundTypeFilterForStats";
import PleaseWait from "../Components/Reuseable/PleaseWait";
import arrow1 from "../assets/image 13 (Traced).png";
import Pagination from "./Pagination";
import Lottie from "lottie-react";
import Animate from "../assets/loader.json";
import HorseModal from "../Components/Modal/HorseModal";
import JockeyModal from "../Components/Modal/JockeyModal";
import { Modal } from "react-bootstrap";
import VideoFrame from "../Components/Modal/VideoFrame.js";
import RaceModal from "../Components/Modal/RaceModal.js";
import ExNews from "../Components/Home/Components/ExNews";
import Search from "../Components/Home/Components/Search";
import Notification from "../Components/Home/Components/Notification";
import Language from "../Components/Home/Components/Language";
import NoHistory from "../Components/Reuseable/NoHistory.jsx";
import { DatePicker } from "antd";

const { RangePicker } = DatePicker;

export default function Horsestatistics() {
  const { data: SireSnippets, status } = useSelector(
    (state) => state.SireSnippets
  );
  const { data: ResultYearFilterForStats } = useSelector(
    (state) => state.ResultYearFilterForStats
  );
  const { data: RaceTypeFilterForStats } = useSelector(
    (state) => state.RaceTypeFilterForStats
  );
  const { data: TrackLengthFilterForStats } = useSelector(
    (state) => state.TrackLengthFilterForStats
  );
  const { data: HorseKindFilterForStats } = useSelector(
    (state) => state.HorseKindFilterForStats
  );
  const { data: RaceKindFilterForStats } = useSelector(
    (state) => state.RaceKindFilterForStats
  );
  const { data: RaceNationalityFilterForStats } = useSelector(
    (state) => state.RaceNationalityFilterForStats
  );
  const { data: RaceCourseFilterForStats } = useSelector(
    (state) => state.RaceCourseFilterForStats
  );
  const { data: GroundTypeFilterForStats } = useSelector(
    (state) => state.GroundTypeFilterForStats
  );
  const { data: DatesFilterForStats } = useSelector(
    (state) => state.DatesFilterForStats
  );

  const { data: SireHistoryForStatisitics } = useSelector(
    (state) => state.SireHistoryForStatisitics
  );
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [modaldata, setmodaldata] = useState();

  const handleClose = () => setShow(false);
  const handleShow = async (data) => {
    setmodaldata(data);
    await setShow(true);
  };

  const [shows, setShows] = useState(false);
  const [modaldatas, setmodaldatas] = useState();
  const handleCloses = () => setShows(false);
  const handleShows = async (data) => {
    setmodaldatas(data);
    await setShows(true);
  };

  const [showJockey, setShowJockey] = useState(false);
  const [modaldataJockey, setmodaldataJockey] = useState();

  const handleCloseJockey = () => setShowJockey(false);
  const handleShowJockey = async (data) => {
    setmodaldataJockey(data);
    await setShowJockey(true);
  };

  const { t } = useTranslation();
  const cookiedata = Cookies.get("i18next");

  let totalcountdata = SireSnippets ? SireSnippets.totalcount : "";
  let totalpagesdata = SireSnippets ? SireSnippets.totalPages : "";
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(11);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const [ResultYearData, setResultYearData] = useState("");
  const [ResultRaceCourseData, setResultRaceCourse] = useState("");
  const [ResultRaceTypeData, setResultRaceType] = useState("");
  const [ResultNationalityData, setResultNationality] = useState("");
  const [ResultRaceTracklengthData, setResultRaceTracklength] = useState("");
  const [ResultRaceKindData, setResultRaceKind] = useState("");
  const [ResultHorseKindData, setResultHorseKind] = useState("");
  const [ResultGroundData, setResultGround] = useState("");
  const [StartDate, setStartDate] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [EndDate, setEndDate] = useState(
    moment().add(1, "months").endOf("month").format("YYYY-MM-DD")
  );
  const handleDateChange = (dates, i) => {
    setStartDate(i[0]);
    setEndDate(i[1]);
  };

  const [compIndex, setCompIndex] = useState();

  let RaceCourseSearch = ResultRaceCourseData
    ? ResultRaceCourseData.id === undefined
      ? ""
      : ResultRaceCourseData.id
    : "";
  let HorseKindSearch = ResultHorseKindData
    ? ResultHorseKindData.id === undefined
      ? ""
      : ResultHorseKindData.id
    : "";
  let TrackLengthSearch = ResultRaceTracklengthData
    ? ResultRaceTracklengthData.id === undefined
      ? ""
      : ResultRaceTracklengthData.id
    : "";
  let RaceTypeSearch = ResultRaceTypeData
    ? ResultRaceTypeData.id === undefined
      ? ""
      : ResultRaceTypeData.id
    : "";
  let RaceKindSearch = ResultRaceKindData
    ? ResultRaceKindData.id === undefined
      ? ""
      : ResultRaceKindData.id
    : "";
  let RaceNationalitySearch = ResultNationalityData
    ? ResultNationalityData.id === undefined
      ? ""
      : ResultNationalityData.id
    : "";
  let RaceGroundSearch = ResultGroundData
    ? ResultGroundData.id === undefined
      ? ""
      : ResultGroundData.id
    : "";

  let year = ResultYearData?.id;
  let Nationality = ResultNationalityData?.id;
  let RaceCourse = ResultRaceCourseData?.id;
  let GroundType = ResultGroundData?.id;
  let RaceKind = ResultRaceKindData?.id;
  let HorseKind = ResultHorseKindData?.id;
  let RaceType = ResultRaceTypeData?.id;
  let TrackLength = ResultRaceTracklengthData?.id;

  useEffect(() => {
    dispatch(
      fetchSireSnippets({
        StartDate,
        EndDate,
        RaceCourseSearch,
        HorseKindSearch,
        TrackLengthSearch,
        RaceTypeSearch,
        RaceKindSearch,
        RaceGroundSearch,
        RaceNationalitySearch,
        currentPage,
      })
    );
  }, [
    StartDate,
    EndDate,
    RaceCourseSearch,
    HorseKindSearch,
    TrackLengthSearch,
    RaceTypeSearch,
    RaceKindSearch,
    RaceGroundSearch,
    RaceNationalitySearch,
    currentPage,
    dispatch,
    year,
    Nationality,
    RaceCourse,
  ]);

  useEffect(() => {
    dispatch(
      fetchResultYearFilterForStats({
        year,
        Nationality,
        RaceCourse,
        GroundType,
        RaceKind,
        HorseKind,
        RaceType,
        TrackLength,
      })
    );
    dispatch(
      fetchRaceTypeFilterForStats({
        year,
        Nationality,
        RaceCourse,
        GroundType,
        RaceKind,
        HorseKind,
        RaceType,
        TrackLength,
      })
    );
    dispatch(
      fetchTrackLengthFilterForStats({
        year,
        Nationality,
        RaceCourse,
        GroundType,
        RaceKind,
        HorseKind,
        RaceType,
        TrackLength,
      })
    );
    dispatch(
      fetchHorseKindFilterForStats({
        year,
        Nationality,
        RaceCourse,
        GroundType,
        RaceKind,
        HorseKind,
        RaceType,
        TrackLength,
      })
    );
    dispatch(
      fetchRaceKindFilterForStats({
        year,
        Nationality,
        RaceCourse,
        GroundType,
        RaceKind,
        HorseKind,
        RaceType,
        TrackLength,
      })
    );
    dispatch(
      fetchRaceNationalityFilterForStats({
        year,
        Nationality,
        RaceCourse,
        GroundType,
        RaceKind,
        HorseKind,
        RaceType,
        TrackLength,
      })
    );
    dispatch(
      fetchRaceCourseFilterForStats({
        year,
        Nationality,
        RaceCourse,
        GroundType,
        RaceKind,
        HorseKind,
        RaceType,
        TrackLength,
      })
    );
    dispatch(
      fetchDatesFilterForStats({
        year,
        Nationality,
        RaceCourse,
        GroundType,
        RaceKind,
        HorseKind,
        RaceType,
        TrackLength,
      })
    );
    dispatch(
      fetchGroundTypeFilterForStats({
        year,
        Nationality,
        RaceCourse,
        GroundType,
        RaceKind,
        HorseKind,
        RaceType,
        TrackLength,
      })
    );
  }, [
    dispatch,
    year,
    Nationality,
    RaceCourse,
    GroundType,
    RaceKind,
    HorseKind,
    RaceType,
    TrackLength,
  ]);

  const [showRace, setShowRace] = useState(false);
  const [modaldataRace, setmodaldataRace] = useState();
  const handleCloseRace = () => setShowRace(false);
  const handleShowRace = async (id) => {
    setmodaldataRace(id);
    await setShowRace(true);
  };

  const handleTrainerRecord = async (id, index) => {
    setCompIndex(index);
    await dispatch(
      fetchSireHistoryForStatisitics({
        id,
        year,
        Nationality,
        RaceCourse,
        GroundType,
        RaceKind,
        HorseKind,
        RaceType,
        TrackLength,
      })
    );
  };

  let RaceYearDropdown = ResultYearFilterForStats.map(function (item) {
    return {
      id: item.RacesYear,
      value: item.RacesYear,
      label: item.RacesYear,
    };
  });

  let RaceTypeFilterForStatsDropdown = RaceTypeFilterForStats.map(function (
    item
  ) {
    return cookiedata === "en"
      ? {
          id: item._id,
          value: item.NameEn,
          label: item.NameEn,
        }
      : {
          id: item._id,
          value: item.NameAr,
          label: item.NameAr,
        };
  });

  let TrackLengthFilterForStatsDropdown = TrackLengthFilterForStats.map(
    function (item) {
      return {
        id: item._id,
        value: item.TrackLength,
        label: item.TrackLength,
      };
    }
  );

  let HorseKindFilterForStatsDropdown = HorseKindFilterForStats.map(function (
    item
  ) {
    return cookiedata === "en"
      ? {
          id: item._id,
          value: item.NameEn,
          label: item.NameEn,
        }
      : {
          id: item._id,
          value: item.NameAr,
          label: item.NameAr,
        };
  });

  let RaceKindFilterForStatsDropdown = RaceKindFilterForStats.map(function (
    item
  ) {
    return cookiedata === "en"
      ? {
          id: item._id,
          value: item.NameEn,
          label: item.NameEn,
        }
      : {
          id: item._id,
          value: item.NameAr,
          label: item.NameAr,
        };
  });

  let RaceNationalityFilterForStatsDropdown = RaceNationalityFilterForStats.map(
    function (item) {
      return cookiedata === "en"
        ? {
            id: item._id,
            value: item.NationalityDataRaceCourse ? (
              item.NationalityDataRaceCourse.NameEn
            ) : (
              <></>
            ),
            label: item.NationalityDataRaceCourse ? (
              item.NationalityDataRaceCourse.NameEn
            ) : (
              <></>
            ),
          }
        : {
            id: item._id,
            value: item.NationalityDataRaceCourse ? (
              item.NationalityDataRaceCourse.NameAr
            ) : (
              <></>
            ),
            label: item.NationalityDataRaceCourse ? (
              item.NationalityDataRaceCourse.NameAr
            ) : (
              <></>
            ),
          };
    }
  );

  let RaceCourseFilterForStatsDropdown = RaceCourseFilterForStats.map(function (
    item
  ) {
    return cookiedata === "en"
      ? {
          id: item._id,
          value: item.TrackNameEn,
          label: item.TrackNameEn,
        }
      : {
          id: item._id,
          value: item.TrackNameAr,
          label: item.TrackNameAr,
        };
  });

  let GroundTypeFilterForStatsDropdown =
    GroundTypeFilterForStats &&
    GroundTypeFilterForStats.map(function (item) {
      return cookiedata === "en"
        ? {
            id: item._id,
            value: item.NameEn,
            label: item.NameEn,
          }
        : {
            id: item._id,
            value: item.NameAr,
            label: item.NameAr,
          };
    });

  function distanceLabel(distance, rank) {
    const DistanceTobeChecked = parseFloat(distance);

    if (DistanceTobeChecked.toFixed(2) > 25) {
      return "Dist";
    } else if (DistanceTobeChecked.toFixed(2) == 0.05 && rank < 80) {
      return "SHd";
    } else if (DistanceTobeChecked.toFixed(2) == 0.1 && rank < 80) {
      return "Hd";
    } else if (DistanceTobeChecked.toFixed(2) == 0.25 && rank < 80) {
      return "Nk";
    } else if (DistanceTobeChecked.toFixed(2) == 0.01 && rank < 80) {
      return "Ns";
    } else if (DistanceTobeChecked.toFixed(2) == 0.0 && rank < 80) {
      if (rank === 1) {
        return 0;
      }
      return "Dh";
    } else {
      return distance;
    }
  }

  return (
    <Fragment>
      <>
        <div className="container mystages trainerStages">
          <div className="row">
            <div className="col-sm-12 stages">
              <div className="raceCarParent stages stages123">
                <div className="raceCardHeader1">
                  <div className="row">
                    <div className="col-sm-10">
                      <h1>{t("Sire Snippets")}</h1>
                    </div>
                  </div>
                </div>
                <div className="posttabs">
                  <div className="row">
                    <div className="Statisticsbtn Statisticsbtn1">
                      <Select
                        id="selectNow2"
                        placeholder={t("Year")}
                        className="dropdown multidropdown"
                        isSearchable={true}
                        hideSelectedOptions={true}
                        name="ResultYearData"
                        isClearable={true}
                        onChange={setResultYearData}
                        options={RaceYearDropdown}
                        value={ResultYearData}
                      />
                      <Select
                        id="selectNow2"
                        placeholder={t("Nationality")}
                        className="dropdown multidropdown"
                        isSearchable={true}
                        hideSelectedOptions={true}
                        isClearable={true}
                        name="ResultNationality"
                        onChange={setResultNationality}
                        options={RaceNationalityFilterForStatsDropdown}
                        value={ResultNationalityData}
                      />
                      <Select
                        id="selectNow2"
                        placeholder={t("Race Course")}
                        className="dropdown multidropdown"
                        isSearchable={true}
                        isClearable={true}
                        hideSelectedOptions={true}
                        name="ResultNationality"
                        onChange={setResultRaceCourse}
                        options={RaceCourseFilterForStatsDropdown}
                        value={ResultRaceCourseData}
                      />
                      <Select
                        id="selectNow2"
                        placeholder={t("Ground Type")}
                        className="dropdown multidropdown"
                        isSearchable={true}
                        hideSelectedOptions={true}
                        name="Meeting"
                        isClearable={true}
                        onChange={setResultGround}
                        options={GroundTypeFilterForStatsDropdown}
                        value={ResultGroundData}
                      />

                      <Select
                        id="selectNow2"
                        placeholder={t("Race Kind")}
                        className="dropdown multidropdown"
                        isSearchable={true}
                        hideSelectedOptions={true}
                        name="ResultNationality"
                        isClearable={true}
                        onChange={setResultRaceKind}
                        options={RaceKindFilterForStatsDropdown}
                        value={ResultRaceKindData}
                      />
                      <Select
                        id="selectNow2"
                        placeholder={t("KindOfHorse")}
                        className="dropdown multidropdown"
                        isSearchable={true}
                        hideSelectedOptions={true}
                        name="ResultNationality"
                        onChange={setResultHorseKind}
                        isClearable={true}
                        options={HorseKindFilterForStatsDropdown}
                        value={ResultHorseKindData}
                      />
                      <Select
                        id="selectNow2"
                        placeholder={t("Track length")}
                        className="dropdown multidropdown"
                        isSearchable={true}
                        hideSelectedOptions={true}
                        name="length"
                        isClearable={true}
                        onChange={setResultRaceTracklength}
                        options={TrackLengthFilterForStatsDropdown}
                        value={ResultRaceTracklengthData}
                      />
                      <Select
                        id="selectNow2"
                        placeholder={t("Race Type")}
                        className="dropdown multidropdown"
                        isSearchable={true}
                        hideSelectedOptions={true}
                        name="ResultNationality"
                        isClearable={true}
                        onChange={setResultRaceType}
                        options={RaceTypeFilterForStatsDropdown}
                        value={ResultRaceTypeData}
                      />
                      <RangePicker
                        onChange={handleDateChange}
                        className="newdatepicker"
                      />
                    </div>
                  </div>
                </div>

                {status == "idle" ? (
                  <div className="formobilescroll1">
                    <table className="statsTable innermodalstats mycss">
                      <thead>
                        <tr className="mycss1">
                          <td>
                            {t("Sire")} {t("Name")}
                          </td>
                          <td>{t("Runs")} </td>
                          <td>{t("Wins")} </td>
                          <td>{t("Second")}</td>
                          <td>{t("Third")} </td>
                          <td>{t("Fourth")} </td>
                          <td>{t("Fifth")} </td>
                          <td>{t("Earning")} </td>
                          <td>{t("Wins")}% </td>
                          <td></td>
                        </tr>
                      </thead>
                    </table>
                    {SireSnippets.data &&
                      SireSnippets.data.map((item, i) => (
                        <>
                          <Accordion>
                            <Accordion.Item
                              className="trainerstatsbtn1"
                              eventKey={i}
                              onClick={() =>
                                handleTrainerRecord(item.HorseSire, i)
                              }
                            >
                              <div>
                                <Accordion.Header>
                                  <table>
                                    <thead>
                                      <tr>
                                        <td
                                          onClick={() =>
                                            handleShow(item.HorseSire)
                                          }
                                          style={{
                                            cursor: "pointer",
                                          }}
                                        >
                                          {cookiedata === "en"
                                            ? window.textTransform(
                                                item.HorseSireData.NameEn
                                              )
                                            : item.HorseSireData.NameAr}
                                        </td>
                                        <td>{item.HorseSireData.Runs}</td>
                                        <td>{item.HorseSireData.wins}</td>
                                        <td>{item.HorseSireData.second}</td>
                                        <td>{item.HorseSireData.third}</td>
                                        <td>{item.HorseSireData.fourth}</td>
                                        <td>{item.HorseSireData.fifth}</td>
                                        <td>{item.HorseSireData.earning}</td>
                                        <td>
                                          {item.HorseSireData.winspercentage}
                                        </td>
                                        <td>
                                          <IoIosArrowDropdownCircle />
                                        </td>
                                      </tr>
                                    </thead>
                                  </table>{" "}
                                </Accordion.Header>

                                {compIndex === i && (
                                  <Accordion.Body
                                    key="i"
                                    className="trainerstatsbtn1"
                                  >
                                    <div className="raceCardTable">
                                      <ScrollContainer>
                                        <div className="mycardclass1">
                                          <div className="BodyNew mobiledatascroll">
                                            <table className="customers">
                                              <thead className="customers5">
                                                <tr>
                                                  <th>{t("Date")}</th>
                                                  <th>{t("Crs")}</th>
                                                  <th>{t("Dist")}</th>
                                                  <th>{t("TC")}</th>
                                                  <th>{t("RT")}</th>

                                                  <th>{t("Dts")}</th>
                                                  <th>{t("Time")}</th>
                                                  <th>{t("Jockey")}</th>
                                                  <th>{t("Wgt")}</th>
                                                  <th>{t("FP")}</th>
                                                  <th>{t("Len")}</th>
                                                  <th>{t("RN")}</th>
                                                  <th>{t("BtBy")}</th>
                                                  <th>{t("Kgs")}</th>

                                                  <th>{t("Draw")}</th>
                                                  <th>{t("Link")}</th>
                                                </tr>
                                              </thead>
                                              <tbody className="customers1">
                                                {SireHistoryForStatisitics?.length ===
                                                0 ? (
                                                  <div class="pleaseWaitnodata nohistory">
                                                    <p>{t("No History Yet")}</p>
                                                    <span>
                                                      <i></i>
                                                      <i></i>
                                                    </span>
                                                  </div>
                                                ) : (
                                                  <>
                                                    {!SireHistoryForStatisitics ? (
                                                      <PleaseWait />
                                                    ) : (
                                                      SireHistoryForStatisitics.map(
                                                        (item, i) => {
                                                          return (
                                                            <tr
                                                              className="BodyNew1 historyhorseth"
                                                              style={{
                                                                background: `${
                                                                  item.RaceResultData
                                                                    ? item
                                                                        .RaceResultData
                                                                        ?.TrackLengthData
                                                                        ?.GroundTypeModelData
                                                                        ?.NameEn ==
                                                                      "Dirt"
                                                                      ? "#9F5109"
                                                                      : item
                                                                          .RaceResultData
                                                                          ?.TrackLengthData
                                                                          ?.GroundTypeModelData
                                                                          ?.NameEn ==
                                                                        "Turf"
                                                                      ? "#5ec40f"
                                                                      : "#949494"
                                                                    : "#949494"
                                                                }`,
                                                              }}
                                                            >
                                                              <td
                                                                className="lb"
                                                                style={{
                                                                  cursor:
                                                                    "pointer",
                                                                }}
                                                                onClick={() =>
                                                                  handleShowRace(
                                                                    item.RaceID
                                                                  )
                                                                }
                                                              >
                                                                <Moment format="DD/MM/YYYY">
                                                                  {item.RaceResultData ===
                                                                  null ? (
                                                                    <></>
                                                                  ) : (
                                                                    item
                                                                      ?.RaceResultData
                                                                      ?.Day
                                                                  )}
                                                                </Moment>
                                                              </td>
                                                              <td>
                                                                {item.RaceResultData ? (
                                                                  cookiedata ===
                                                                  "en" ? (
                                                                    item.RaceResultData &&
                                                                    item
                                                                      ?.RaceResultData
                                                                      ?.RaceCourseData
                                                                      ?.AbbrevEn
                                                                  ) : (
                                                                    item.RaceResultData &&
                                                                    item
                                                                      ?.RaceResultData
                                                                      ?.RaceCourseData
                                                                      ?.AbbrevAr
                                                                  )
                                                                ) : (
                                                                  <>- </>
                                                                )}
                                                                (
                                                                {cookiedata ===
                                                                "en"
                                                                  ? item.RaceResultData &&
                                                                    item.RaceResultData?.TrackLengthData?.GroundTypeModelData.NameEn.charAt(
                                                                      0
                                                                    )
                                                                  : item.RaceResultData &&
                                                                    item
                                                                      ?.RaceResultData
                                                                      ?.TrackLengthData
                                                                      ?.GroundTypeModelData
                                                                      ?.NameAr}
                                                                )
                                                              </td>
                                                              <td>
                                                                {distanceLabel(
                                                                  item.Distance &&
                                                                    item.Distance,
                                                                  item.FinalPositionDataHorse &&
                                                                    item
                                                                      .FinalPositionDataHorse
                                                                      ?.Rank
                                                                )}
                                                              </td>
                                                              <td>
                                                                {item.RaceResultData
                                                                  ? cookiedata ===
                                                                    "en"
                                                                    ? item.RaceResultData &&
                                                                      item
                                                                        ?.RaceResultData
                                                                        ?.TrackConditionData
                                                                        ?.NameEn
                                                                    : item.RaceResultData &&
                                                                      item
                                                                        ?.RaceResultData
                                                                        ?.TrackConditionData
                                                                        ?.NameAr
                                                                  : "_"}
                                                              </td>
                                                              <td>
                                                                {item.RaceResultData
                                                                  ? cookiedata ===
                                                                    "en"
                                                                    ? item.RaceResultData &&
                                                                      item
                                                                        .RaceResultData
                                                                        ?.RaceTypeModelData
                                                                        ?.NameEn
                                                                    : item.RaceResultData &&
                                                                      item
                                                                        .RaceResultData
                                                                        ?.RaceTypeModelData
                                                                        ?.NameAr
                                                                  : "_"}
                                                              </td>
                                                              <td>
                                                                {item.PrizeWin
                                                                  ? item.PrizeWin
                                                                  : 0}
                                                              </td>
                                                              <td>
                                                                {item.RaceTime}
                                                              </td>
                                                              <td
                                                                onClick={() =>
                                                                  handleShowJockey(
                                                                    item.JockeyOnRaceDataResult
                                                                      ? item
                                                                          ?.JockeyOnRaceDataResult
                                                                          ._id
                                                                      : ""
                                                                  )
                                                                }
                                                                style={{
                                                                  cursor:
                                                                    "pointer",
                                                                }}
                                                              >
                                                                {item.JockeyOnRaceDataResult
                                                                  ? cookiedata ===
                                                                    "en"
                                                                    ? window.textTransform(
                                                                        item
                                                                          ?.JockeyOnRaceDataResult
                                                                          ?.NameEn
                                                                      )
                                                                    : item
                                                                        ?.JockeyOnRaceDataResult
                                                                        ?.NameAr
                                                                  : "_"}
                                                              </td>
                                                              <td>
                                                                {item.BeatenByData &&
                                                                  item
                                                                    ?.BeatenByData
                                                                    ?.WinningHorseJockeyWeight}
                                                              </td>
                                                              <td>
                                                                {item.FinalPositionDataHorse ===
                                                                null ? (
                                                                  <></>
                                                                ) : (
                                                                  <>
                                                                    {cookiedata ===
                                                                    "en" ? (
                                                                      <>
                                                                        {cookiedata ===
                                                                        "en"
                                                                          ? item.FinalPositionDataHorse &&
                                                                            item
                                                                              .FinalPositionDataHorse
                                                                              ?.NameEn
                                                                          : item.FinalPositionDataHorse &&
                                                                            item
                                                                              .FinalPositionDataHorse
                                                                              ?.NameAr}
                                                                      </>
                                                                    ) : (
                                                                      <>
                                                                        {
                                                                          item
                                                                            ?.FinalPositionDataHorse
                                                                            ?.NameAr
                                                                        }
                                                                      </>
                                                                    )}
                                                                  </>
                                                                )}
                                                              </td>
                                                              <td>
                                                                {item.RaceResultData ? (
                                                                  item
                                                                    .RaceResultData
                                                                    ?.TrackLengthData
                                                                    ?.TrackLength
                                                                ) : (
                                                                  <>-</>
                                                                )}
                                                              </td>
                                                              <td>
                                                                {item.Runners}
                                                              </td>
                                                              <td
                                                                onClick={() =>
                                                                  handleShow(
                                                                    item.BeatenByData
                                                                      ? item.BeatenByData
                                                                      : ""
                                                                  )
                                                                }
                                                                style={{
                                                                  cursor:
                                                                    "pointer",
                                                                }}
                                                              >
                                                                {item.BeatenByData ===
                                                                null ? (
                                                                  <>
                                                                    {cookiedata ===
                                                                    "en"
                                                                      ? window.textTransform(
                                                                          item
                                                                            ?.HorseIDData
                                                                            ?.HorseBeatenByEnglishName
                                                                        )
                                                                      : item
                                                                          ?.HorseIDData
                                                                          ?.HorseBeatenByArabicName}
                                                                  </>
                                                                ) : (
                                                                  <>
                                                                    {cookiedata ===
                                                                    "en" ? (
                                                                      <>
                                                                        {window.textTransform(
                                                                          item
                                                                            ?.BeatenByData
                                                                            ?.NameEn
                                                                        )}
                                                                      </>
                                                                    ) : (
                                                                      <>
                                                                        {
                                                                          item
                                                                            ?.BeatenByData
                                                                            ?.NameAr
                                                                        }
                                                                      </>
                                                                    )}
                                                                  </>
                                                                )}
                                                              </td>{" "}
                                                              <td>
                                                                {item.BeatenByData !==
                                                                null
                                                                  ? item
                                                                      .BeatenByData
                                                                      ?.WinningHorseJockeyWeight
                                                                  : item
                                                                      ?.HorseIDData
                                                                      ?.BeatenByJockeyWeight ===
                                                                    null
                                                                  ? 0
                                                                  : item
                                                                      ?.HorseIDData
                                                                      ?.BeatenByJockeyWeight}
                                                              </td>
                                                              <td>
                                                                {item.HorseNo ? (
                                                                  item.HorseNo &&
                                                                  item?.HorseNo
                                                                ) : (
                                                                  <>- </>
                                                                )}
                                                              </td>
                                                              <td
                                                                className="rb"
                                                                onClick={() =>
                                                                  handleShows(
                                                                    item.VideoLink
                                                                  )
                                                                }
                                                                style={{
                                                                  cursor:
                                                                    "pointer",
                                                                }}
                                                              >
                                                                <img
                                                                  src={arrow1}
                                                                  alt=""
                                                                />
                                                              </td>
                                                            </tr>
                                                          );
                                                        }
                                                      )
                                                    )}
                                                  </>
                                                )}
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </ScrollContainer>
                                    </div>
                                  </Accordion.Body>
                                )}
                              </div>
                            </Accordion.Item>
                          </Accordion>
                        </>
                      ))}
                  </div>
                ) : (
                  <Lottie
                    animationData={Animate}
                    className="load1"
                    style={{
                      marginTop: "30px",
                    }}
                  />
                )}
              </div>

              <Pagination
                postsPerPage={postsPerPage}
                totalPosts={totalcountdata}
                paginate={paginate}
                currentPage={currentPage}
                TotalPages={totalpagesdata}
              />
            </div>
          </div>
        </div>
        <Modal
          show={shows}
          onHide={handleCloses}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="popupheader" closeButton></Modal.Header>
          <Modal.Body>
            <VideoFrame data={modaldatas} />
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>

        <Modal
          show={show}
          onHide={handleClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="popupheader" closeButton></Modal.Header>
          <Modal.Body>
            <HorseModal data={modaldata} />
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>

        <Modal
          show={showJockey}
          onHide={handleCloseJockey}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="popupheader" closeButton></Modal.Header>
          <Modal.Body>
            <JockeyModal data={modaldataJockey} />
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>

        <Modal
          show={showRace}
          onHide={handleCloseRace}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="popupheader" closeButton></Modal.Header>
          <Modal.Body>
            <RaceModal id={modaldataRace} />
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
        <Footer />
        <CoptRight />
      </>
    </Fragment>
  );
}
